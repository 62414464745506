<template>
  <div class="recipient-domiciliation">
    <div class="list-dom pt-2 pl-4 pr-4">
      <widget headerText="Liste des domiciliations">
        <b-table
          v-if="domiciliations.length > 0"
          striped
          hover
          :items="domiciliations"
          :fields="fields"
          :tbody-tr-class="rowClass"
        >
          <template #cell(actions)="row">
            <ion-icon
              name="newspaper"
              style="cursor: pointer"
              @click="downloadCerfa(row.item)"
              v-b-tooltip.hover
              title="Télécharger le Cerfa"
            ></ion-icon>
            <ion-icon
              name="refresh"
              style="cursor: pointer"
              @click="refreshCerfa(row.item)"
              v-b-tooltip.hover
              title="RéModifier le Cerfa"
            ></ion-icon>
            <ion-icon
              name="pencil"
              v-b-tooltip.hover
              style="cursor: pointer"
              title="Modifier les informations"
              @click="modifyDom(row.item)"
            ></ion-icon>
            <ion-icon
              name="trash"
              v-b-tooltip.hover
              style="cursor: pointer"
              title="Supprimer la Domiciliation"
              @click="askDelete(row.item)"
            ></ion-icon> </template
        ></b-table>
        <button class="btn btn-primary" type="button" @click="addDomiciliation">
          Ajouter une domiciliation
        </button>
      </widget>
    </div>
    <div class="list-mail p-4">
      <widget headerText="Liste des courriers">
        <b-table
          v-if="mails.length > 0"
          striped
          hover
          :items="mails"
          :fields="mailFields"
        >
          <template #cell(actions)="row">
            <ion-icon
              name="pencil"
              v-b-tooltip.hover
              style="cursor: pointer"
              title="Modifier le courrier"
              @click="modifyMail(row.item)"
            ></ion-icon>
            <ion-icon
              name="trash"
              v-b-tooltip.hover
              style="cursor: pointer"
              title="Supprimer le courrier"
              @click="askDeleteMail(row.item)"
            ></ion-icon> </template
        ></b-table>
        <div class="flex">
        <div class="pr-2 pb-2">
        <button class="btn btn-primary" type="button" @click="addMail">
          Ajouter un courrier
        </button>
        </div>
        <div class="pl-2 pt-2">
        <button class="btn btn-secondary" type="button" @click="setNonAlertable">
          Désactiver les alertes sur ces courriers
        </button>
        </div>
        </div>
        <div class="d-flex justify-content-around align-items-center">
          <div class="md-layout-item md-size-100">
            <md-field>
              <label for="mail-redirection">Faire suivre</label>
              <md-input
                name="mail-redirection"
                id="mail-redirection"
                v-model="mailRedirection"
                @input="updateMail"
              />
            </md-field>
          </div>
        </div>
      </widget>
    </div>
    <comments
      :recipient_id="recipient_id"
      field="domiciliation"
      @openCommentModal="openCommentModal"
    />
  </div>
</template>

<script>
import Widget from "../widgets/Widget.vue";
import { mapState } from "vuex";
import Comments from "../../components/comment/Comments.vue";
import axios from "axios";
export default {
  components: { Widget, Comments },
  name: "recipient-domiciliation",
  props: {
    recipient_id: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      domiciliations: [],
      mails: [],
      mailRedirection: null,
      mailFields: [
        {
          key: "date",
          label: "Date d'arrivée",
          sortable: true,
          formatter: (val) => {
            return val ? this.$moment(val).format("DD/MM/YYYY") : "";
          },
        },
        {
          key: "user",
          label: "Accueillant",
          formatter: (val) => {
            return val ? val.initials : "";
          },
        },
        {
          key: "number",
          label: "Nombre de plis",
        },
        {
          key: "received_date",
          label: "Date de reception",
          sortable: true,
          formatter: (val) => {
            return val ? this.$moment(val).format("DD/MM/YYYY") : "";
          },
        },
        {
          key: "non_alertable",
          label: "Alerte",
          formatter: (val) => {
            return val == undefined ? "Activée": "Inactive"
          }
        },
        {
          key: "actions",
          label: "Actions",
        },
      ],
      fields: [
        {
          key: "start_date",
          label: "Date de début",
          sortable: true,
          formatter: (val) => {
            return val ? this.$moment(val).format("DD/MM/YYYY") : "";
          },
        },
        {
          key: "end_date",
          label: "Date de fin",
          formatter: (val) => {
            return val ? this.$moment(val).format("DD/MM/YYYY") : "";
          },
        },
        {
          key: "paper",
          label: "Justificatif",
        },
        {
          key: "antenna_id",
          label: "Antenne",
          sortable: true,
          formatter: (val) => {
            return val
              ? this.antennas.filter((a) => a._id == val)[0].name
              : "Hors MRS";
          },
        },
        {
          key: "user",
          label: "Accueillant",
          sortable: true,
          formatter: (val) => (val ? val.initials : ""),
        },
        {
          key: "name_dom",
          label: "Accueillant hors MRS",
          sortable: true,
          formatter: (val) => (val ? val : ""),
        },
        {
          key: "actions",
          label: "Actions",
        },
      ],
    };
  },
  watch: {
    refreshFlag(flag) {
      if (flag == "domiciliation") {
        this.refreshDomiciliations();
        this.$store.dispatch("setRefreshFlag", false);
      }
      if (flag == "mail") {
        this.refreshMails();
        this.$store.dispatch("setRefreshFlag", false);
      }
    },
  },
  computed: {
    ...mapState(["user", "antennas", "refreshFlag"]),
  },
  mounted() {
    this.refreshDomiciliations();
    this.refreshMails();
  },
  methods: {
    downloadCerfa(dom) {
      axios
        .get(`/api/web/domiciliation/${dom._id}/download_cerfa`, {
          responseType: "blob",
        })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", res.headers.filename);
          document.body.appendChild(link);
          link.click();
          this.isLoading = false;
        });
    },
    refreshCerfa(dom) {
      axios
        .get(`/api/web/domiciliation/${dom._id}/refresh_cerfa`, {
          responseType: "blob",
        })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", res.headers.filename);
          document.body.appendChild(link);
          link.click();
          this.isLoading = false;
        });
    },
    askDelete(dom) {
      if (this.user.rights.indexOf("dom_management") > 0) {
        this.$toasted
          .show("Souhaitez-vous supprimer la domiciliation", {
            action: [
              {
                text: "Confimer",
                onClick: (_, toastObject) => {
                  this.delete(dom._id);
                  toastObject.goAway(0);
                },
              },
              {
                text: "Annuler",
                onClick: (_, toastObject) => {
                  toastObject.goAway(0);
                },
              },
            ],
          })
          .goAway(10000);
      } else {
        this.$toasted.show("Vous n'avez pas les droits").goAway(3000);
      }
    },
    delete(dom_id) {
      axios
        .delete(`/api/web/domiciliation/${dom_id}`)
        .then(() => {
          this.$toasted.show("La domiciliation a été supprimée").goAway(1500);
          this.refreshDomiciliations();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    askDeleteMail(mail) {
      this.$toasted
        .show("Souhaitez-vous supprimer le courrier", {
          action: [
            {
              text: "Confimer",
              onClick: (_, toastObject) => {
                this.deleteMail(mail._id);
                toastObject.goAway(0);
              },
            },
            {
              text: "Annuler",
              onClick: (_, toastObject) => {
                toastObject.goAway(0);
              },
            },
          ],
        })
        .goAway(10000);
    },
    deleteMail(mail_id) {
      axios
        .delete(`/api/web/mail/${mail_id}`)
        .then(() => {
          this.$toasted.show("Le courrier a été supprimée").goAway(1500);
          this.refreshMails();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    addDomiciliation() {
      let isRadiated = false;
      axios.get(`/api/web/recipient/${this.recipient_id}`).then((res) => {
        isRadiated = res.data.data.radiated;
        if (!isRadiated) {
          this.$emit("openDomModal", {
            recipient_id: this.recipient_id,
            domiciliation: { user_id: this.user._id },
            antenna_id: this.user.antenna_id,
          });
        } else {
          this.$toasted
            .error("Cet accueilli est radié. Il ne peut pas être domicilié.")
            .goAway(5000);
          this.$toasted
            .show(
              "Vous pouvez désactiver la radiation dans l'onglet 'Identité'."
            )
            .goAway(5000);
        }
      });
    },
    modifyDom(dom) {
      this.$emit("openDomModal", {
        recipient_id: this.recipient_id,
        domiciliation: dom,
        antenna_id: this.user.antenna_id,
      });
    },
    addMail() {
      this.$emit("openMailModal", {
        recipient_id: this.recipient_id,
        mail: {},
        antenna_id: this.user.antenna_id,
      });
    },
    setNonAlertable() {
      axios.post(`/api/web/recipient/${this.recipient_id}/mails/deactivate_alerts`)
      .then(() => {
        this.$store.dispatch("setRefreshFlag", "mail");
      })
    },
    modifyMail(mail) {
      this.$emit("openMailModal", {
        recipient_id: this.recipient_id,
        mail: mail,
        antenna_id: this.user.antenna_id,
      });
    },
    refreshDomiciliations() {
      axios
        .get(`/api/web/recipient/${this.recipient_id}/domiciliations`)
        .then((res) => {
          this.domiciliations = res.data.data;
        });
    },
    refreshMails() {
      axios.get(`/api/web/recipient/${this.recipient_id}/mails`).then((res) => {
        this.mails = res.data.data;
      });
      axios
        .get(`/api/web/recipient/${this.recipient_id}/mail_redirection`)
        .then((res) => {
          this.mailRedirection = res.data.data;
        });
    },
    updateMail() {
      let body = {
        mail_redirection: this.mailRedirection,
      };
      axios
        .post(`/api/web/recipient/${this.recipient_id}/mail_redirection`, body)
        .then(() => {});
    },
    openCommentModal(payload) {
      this.$emit("openCommentModal", payload);
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.valid) return "table-success";
    },
  },
};
</script>

<style lang="scss" scoped>
</style>